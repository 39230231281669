import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../actions/userActions';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './Profile.css';
import FriendsList from './friendsList';

const Profile = () => {
  const { id } = useParams();
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const isUserDetailsFetched = useRef(false);

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else if (!isUserDetailsFetched.current && userInfo.token) {
      dispatch(getUserDetails(id));
      isUserDetailsFetched.current = true;
    }
  }, [dispatch, navigate, userInfo, id]);

  useEffect(() => {
    const fetchQuestionsAnswered = async () => {
      if (!userInfo?.token) return null;
  
      try {
        const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
        const { data } = await axios.get('https://api.andrewslearning.com/api/questions_answered', config);
        setQuestionsAnswered((data.correct_responses || 0) + (data.incorrect_responses || 0));
        return data; // Return data to capture it outside
      } catch (error) {
        console.error('Error fetching questions answered:', error.message);
        return null;
      }
    };
  
    if (userDetails.user) {
      fetchQuestionsAnswered().then(data => {
        if (data) {
          console.log("Fetched questions data:", data);
        }
      });
    }
  }, [userInfo, userDetails.user]);
  const handleCancelSubscription = async () => {
    if (!userInfo?.token) return;
    try {
      const config = { headers: { Authorization: `Bearer ${userInfo.token}` } };
      const response = await axios.post(
        'https://api.andrewslearning.com/api/cancel_subscription',
        { user_id: userInfo.id },
        config
      );

      alert(response.data?.detail ? 'Subscription canceled successfully.' : 'Failed to cancel subscription. Please try again.');
    } catch (error) {
      console.error('Error canceling subscription:', error.message);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <div className="profile-page-container">
      <div className="profile-container">
        <div className="profile-pic-placeholder">
          {userDetails.user?.Propic ? <img src={userDetails.user.Propic} alt="Profile" /> : 'Profile Picture'}
        </div>
        <h2 className="username-display">{userDetails.user?.username || ''}</h2>
        <p className="profile-email"><strong>Email:</strong> {userDetails.user?.email || ''}</p>
        <p className="profile-streak"><strong>Streak:</strong> {userDetails.user?.streak || 0} days</p>
        <p className="profile-questions-answered"><strong>Questions Answered:</strong> {questionsAnswered}</p>
        <Button variant="primary" className="update-button">Update Profile</Button>
        <Button variant="danger" onClick={handleCancelSubscription} className="cancel-button">Cancel Subscription</Button>
      </div>

      {/* Friends List Column */}
      <div className="friends-list-container">
        <FriendsList />
      </div>
    </div>
  );
};

export default Profile;
