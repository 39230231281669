import React, { useState } from 'react';
import './Chat.css';

const MessageInput = ({ sendMessage }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      sendMessage(inputValue); // Send the message using the WebSocket
      setInputValue(''); // Clear the input after sending the message
    }
  };

  return (
    <div className='message-input'>
      <textarea 
        placeholder='Type your message'
        value={inputValue}
        onChange={handleInputChange}
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default MessageInput;
