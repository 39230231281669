import React from 'react';
import Message from './Message';
import './Chat.css';
import MessageInput from './MessageInput';

const ChatArea = ({ messages = [], sendMessage }) => {
  return (
    <div className='chat-area'>
      <div className='chat-header'></div> 
      <div className="messages">
        {messages.map((message, index) => (
          <Message key={index} text={message.text} sent={message.sent} />
        ))}
      </div>
      <MessageInput sendMessage={sendMessage} /> {/* Pass the sendMessage function */}
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default ChatArea;
