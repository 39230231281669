// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  onlineUsersReducer, // Import the onlineUsersReducer
} from './reducers/userReducers';
import { cartReducer } from './reducers/userReducers'; // Update the import path if necessary

// Load cart items from localStorage
const cartItemsFromStorage = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];

// Load user info from localStorage
const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

// Initial state
const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
    cart: { cartItems: cartItemsFromStorage },
};

// Configure store
const store = configureStore({
  reducer: {
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    cart: cartReducer,
    onlineUsersState: onlineUsersReducer, // Add the onlineUsersReducer here

  },
  preloadedState: initialState,
});

export default store;
