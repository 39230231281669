import { useState, useEffect } from 'react';

const useSound = (url) => {
    const [audio] = useState(new Audio(url));

    const play = () => {
        audio.play();
    };

    useEffect(() => {
        // Clenup function to pause and reset the audio when the component is unmounted
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [audio]);

    return play;
};

export default useSound;
