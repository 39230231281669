import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './friendsList.css';

const FriendsList = () => {
  const [friendsData, setFriendsData] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [outgoingRequests, setOutgoingRequests] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfileName, setSelectedProfileName] = useState(''); // Store profile username
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const { user } = useSelector((state) => state.userDetails);
  const access_token = useSelector((state) => state.userLogin.userInfo?.access);

  useEffect(() => {
    const fetchFriendsAndProfiles = async () => {
      if (!user || !access_token) return;

      try {
        setLoading(true);
        setError(null);
        const config = { headers: { Authorization: `Bearer ${access_token}` } };

        // Fetch all profiles
        const profilesResponse = await axios.get('https://api.andrewslearning.com/api/profiles/', config);
        setProfiles(profilesResponse.data);

        // Fetch friends' profiles
        const friendRequests = user.friends.map(friendId =>
          axios.get(`https://api.andrewslearning.com/api/friendsprofile/${friendId}`, config)
        );
        const friendResponses = await Promise.all(friendRequests);
        setFriendsData(friendResponses.map(response => response.data));

        // Fetch incoming and outgoing friend requests
        const incomingRequests = await axios.get('https://api.andrewslearning.com/api/friendrequests/', config);
        setFriendRequests(incomingRequests.data);

        const outgoingRequestsResponse = await axios.get('https://api.andrewslearning.com/api/friendrequests/sent/', config);
        setOutgoingRequests(outgoingRequestsResponse.data);

      } catch (err) {
        setError(err.response?.data.message || err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFriendsAndProfiles();
  }, [user, access_token]);

  const handleSendRequest = async () => {
    if (!selectedProfileName) return;

    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      // Send the request using the username
      await axios.post(`https://api.andrewslearning.com/api/friendrequests/send/${selectedProfileName}/`, {}, config);
      setMessage('Friend request sent.');
      setSelectedProfileName(''); // Clear selection after sending request
    } catch (err) {
      setMessage(`Error sending request: ${err.response?.data.message || err.message}`);
    }
  };

  const handleAcceptRequest = async (requestId) => {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      await axios.post(`https://api.andrewslearning.com/api/accept_friend_request/${requestId}/`, {}, config);
      setFriendRequests(friendRequests.filter(req => req.id !== requestId));
      setMessage("Friend request accepted.");
    } catch (err) {
      setError(err.response?.data.message || err.message);
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      await axios.post(`https://api.andrewslearning.com/api/friendrequests/${requestId}/reject/`, {}, config);
      setFriendRequests(friendRequests.filter(req => req.id !== requestId));
      setMessage("Friend request rejected.");
    } catch (err) {
      setError(err.response?.data.message || err.message);
    }
  };

  const handleCancelRequest = async (requestId) => {
    try {
      const config = { headers: { Authorization: `Bearer ${access_token}` } };
      await axios.post(`https://api.andrewslearning.com/api/friendrequests/${requestId}/cancel/`, {}, config);
      setOutgoingRequests(outgoingRequests.filter(req => req.id !== requestId));
      setMessage("Friend request canceled.");
    } catch (err) {
      setError(err.response?.data.message || err.message);
    }
  };

  if (loading) return <p>Loading friends and profiles...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="friends-list">
      <h3>Friends List</h3>
      {friendsData.length > 0 ? (
        <ul>
          {friendsData.map((friend) => (
            <li key={friend.id} className="friend-item">
              <span className={`status-indicator ${friend.is_online ? 'online' : 'offline'}`}></span>
              <span className="friend-name">{friend.username}</span>
              <span className={`status-text ${friend.is_online ? 'online-text' : 'offline-text'}`}>
                {friend.is_online ? 'Online' : 'Offline'}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p>No friends added.</p>
      )}

      <h3>Send Friend Request</h3>
      <div className="send-friend-request">
        <select
          value={selectedProfileName}
          onChange={(e) => setSelectedProfileName(e.target.value)}  // Store username
        >
          <option value="">Select a user</option>
          {profiles.map((profile) => (
            <option key={profile.id} value={profile.username}>  {/* Send username */}
              {profile.username}
            </option>
          ))}
        </select>
        <button onClick={handleSendRequest} disabled={!selectedProfileName}>Send Request</button>
      </div>
      {message && <p>{message}</p>}

      <h3>Pending Friend Requests</h3>
      {friendRequests.length > 0 ? (
        <ul>
          {friendRequests.map((request) => (
            <li key={request.id} className="friend-request-item">
              <span>{request.sender.username}</span>
              <button onClick={() => handleAcceptRequest(request.id)}>Accept</button>
              <button onClick={() => handleRejectRequest(request.id)}>Reject</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No pending friend requests.</p>
      )}

      <h3>Outgoing Friend Requests</h3>
      {outgoingRequests.length > 0 ? (
        <ul>
          {outgoingRequests.map((request) => (
            <li key={request.id} className="outgoing-request-item">
              <span>{request.receiver.username}</span>
              <button onClick={() => handleCancelRequest(request.id)}>Cancel Request</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No outgoing friend requests.</p>
      )}
    </div>
  );
};

export default FriendsList;
