export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS'
export const CART_ADD_SUCCESS = 'CART_ADD_SUCCESS';
export const CART_TOTAL = 'CART_TOTAL'
export const APPLY_DISCOUNT = 'APPLY_DISCOUNT'
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAIL = 'CHECKOUT_FAIL'
export const UPDATE_TOTAL_COST = 'UPDATE_TOTAL_COST'


export const COURSE_ACCESS_GRANTED = 'COURSE_ACCESS_GRANTED'

export const CART_RESET_SUCCESS_MESSAGE = 'CART_RESET_SUCCESS_MESSAGE'
export const CART_ADD_ITEM_FAIL = 'CART_ADD_ITEM_FAIL'


export const VALIDATE_DISCOUNT_CODE_REQUEST = 'VALIDATE_DISCOUNT_CODE_REQUEST'
export const VALIDATE_DISCOUNT_CODE_SUCCESS = 'VALIDATE_DISCOUNT_CODE_SUCCESS'
export const VALIDATE_DISCOUNT_CODE_FAIL = 'VALIDATE_DISCOUNT_CODE_FAIL'

export const SUBSCRIPTION_ADD_ITEM = 'SUBSCRIPTION_ADD_ITEM'

// constants/userConstants.js
export const USER_ONLINE_UPDATE = 'USER_ONLINE_UPDATE';
export const USER_ONLINE_REMOVE = 'USER_ONLINE_REMOVE';
export const USER_ONLINE_RESET = 'USER_ONLINE_RESET';
