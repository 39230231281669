import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './video.css'; // Ensure this path matches your CSS file's location

const VideoDetail = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);

  function getEmbedUrl(url) {
    let videoId = url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return `https://www.youtube.com/embed/${videoId}`;
  }

  useEffect(() => {
    fetch(`https://api.andrewslearning.com/api/get_video/${videoId}`) // Adjust this URL based on your API
      .then(response => {
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        return response.json();
      })
      .then(data => setVideo(data))
      .catch(err => setError(err.toString()));
  }, [videoId]);

  if (error) {
    return <div className="video-error"><p>Error loading video: {error}</p></div>;
  }

  if (!video) {
    return <div className="video-loading"><p>Loading...</p></div>;
  }

  return (
<div className="video-wrapper">
    <div className="video-content">
        <div className="video-metadata">
            <h2 className="video-title">{video.title}</h2>
            <p className="video-description">{video.description}</p>
        </div>
        <div className="video-container">
            <iframe
                src={getEmbedUrl(video.video)}
                title="Embedded Video"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="video-frame"
            ></iframe>
        </div>
    </div>
</div>

  );
};

export default VideoDetail;
