import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, getUserDetails } from '../actions/userActions';
import './AdaptiveAssessments.css';

function AdaptiveAssessments() {
  const [assessments, setAssessments] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const access_token = userInfo ? userInfo.access : null;
  const successMessage = useSelector((state) => state.cart.successMessage);
  const failMessage = useSelector((state) => state.cart.failMessage);
  const prevSuccessMessageRef = useRef();

  useEffect(() => {
    const prevSuccessMessage = prevSuccessMessageRef.current;
    if (successMessage && successMessage !== prevSuccessMessage) {
      alert(successMessage);
    }
    prevSuccessMessageRef.current = successMessage;
  }, [successMessage]);

  useEffect(() => {
    if (failMessage) {
      alert(failMessage);
      navigate("/Register#RegistrationForm");
    }
  }, [failMessage, navigate]);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const response = await fetch('https://api.andrewslearning.com/api/assessments/');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setAssessments(data);
      } catch (error) {
        console.error('Error retrieving assessments:', error);
        setError(error.toString());
      }
    };

    fetchAssessments();
  }, []);

  useEffect(() => {
    if (!user || !user.id) {
      const userId = userInfo ? userInfo.id : null;
      if (userId) {
        dispatch(getUserDetails(userId));
      }
    }
  }, [dispatch, userInfo, user]);

  const startAssessment = async (assessment) => {
    if (!access_token) {
      alert('Must be logged in to start assessments');
      return;
    }

    try {
      const response = await fetch(`https://api.andrewslearning.com/api/start_assessment/${assessment.id}/`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${access_token}` },
      });

      if (response.status === 200) {
        const data = await response.json();
        if (assessment.CAT) {
          navigate(`/catassessments/${assessment.id}`, { state: { assessmentResponseId: data.assessment_response_id } });
        } else {
          navigate(`/assessments/${assessment.id}`, { state: { assessmentResponseId: data.assessment_response_id } });
        }
      } else {
        console.error('Failed to start assessment with status:', response.status);
      }
    } catch (error) {
      console.error('Error starting assessment:', error);
    }
  };

  const renderButton = (assessment) => {
    if (!user || Object.keys(user).length === 0) {
      if (!assessment.isPaid) {
        return (
          <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>
            Start Free Assessment
          </button>
        );
      } else {
        return (
          <button className="add-to-cart-button" onClick={() => navigate('/Register#RegistrationForm')}>
            Need Subscription, Register now!
          </button>
        );
      }
    } else {
      if (!assessment.isPaid) {
        return (
          <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>
            Start Free Assessment
          </button>
        );
      } else {
        if (user.has_active_subscription) {
          return (
            <button className="start-assessment-button" onClick={() => startAssessment(assessment)}>
              Start Assessment
            </button>
          );
        } else {
          return (
            <button className="add-to-cart-button" onClick={() => navigate('/Cart')}>
              Need Subscription, Become a Premium member!
            </button>
          );
        }
      }
    }
  };

  const filteredAssessments = assessments.filter(assessment =>
    assessment.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="adaptive-container">
      <center><h5>Connect with Ada, Our Adaptive Assessment Algorithm, to Start Your Learning Journey</h5></center>
      <center> 
        <img id="Ada" src="https://andrewslearningimage-bucket.s3.amazonaws.com/Ada.png" alt="Ada" />
      </center>
      <div className="irt-cat-intro">
        <center>
          <h2>Why Choose Our Adaptive Assessments?</h2>
        </center>
        <p>
          Meet Ada – your personalized learning guide. Ada leverages the latest in <strong>Item Response Theory (IRT)</strong> and <strong>Computer Adaptive Testing (CAT)</strong> to provide an assessment experience uniquely customized for you. Ready to adapt and evolve with your learning needs, Ada offers an accurate measure of your knowledge and skills.
        </p>
        <p>
          <strong>Ada's Promise to You:</strong>
          <ul>
            <li><strong>Customized Challenge:</strong> Ada's intelligence adjusts to your level, ensuring you're always engaged.</li>
            <li><strong>Efficient Learning:</strong> Spend time only on questions that aid your growth.</li>
            <li><strong>Detailed Feedback:</strong> Get actionable insights into your learning journey.</li>
          </ul>
        </p>
        <p>
          Join other learners who have advanced their careers and education with our adaptive assessments. Your path to mastery begins here.
        </p>
      </div>
      <center>
        <h1 id="Assessments">Assessments</h1>
      </center>
      <input
        type="text"
        placeholder="Search assessments..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />
      <div className="assessments-grid">
        {filteredAssessments.map((assessment) => (
          <div key={assessment.id} className="assessment-card">
            <img src={assessment.image || 'https://via.placeholder.com/150'} alt={assessment.name} className="assessment-image" />
            <div className="assessment-content">
              <h2 className="assessment-title">{assessment.name}</h2>
              <p className="assessment-description">{assessment.description}</p>
              {renderButton(assessment)}
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default AdaptiveAssessments;
