import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart } from '../actions/userActions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from "./CheckoutForm";
import { useLocation } from 'react-router-dom'; // Import to access location state
import './Checkouttwo.css';

// Stripe configuration
const stripePromise = loadStripe('pk_live_51OGPqzFg0IM8tPHkISRnJ4WyaCkWlM3A775ipg5gqUxcQZ05tN8Bh7FJLLDRf3pqf9nk2TFGNJBD18m5qPbmcY7x00eA46JtNp');

export default function Checkout() {
  const cartItems = useSelector(state => state.cart.cartItems); // Cart items from Redux state
  const totalCost = useSelector(state => state.cart.totalCost); // Total cost from Redux state
  const [clientSecret, setClientSecret] = useState('');
  const dispatch = useDispatch();

  // Extract data from location state passed from previous component (e.g., Cart)
  const location = useLocation();
  const { subscriptionType, discountCode, discountApplied } = location.state || {};

  // Retrieve user ID from local storage
  const getUserIdFromLocalStorage = () => {
    const userInfo = localStorage.getItem('userInfo');
    return userInfo ? JSON.parse(userInfo).id : null;
  };

  // Remove an item from the cart
  const handleRemoveFromCart = (id, type) => {
    dispatch(removeFromCart(id, type));
  };

  // Format total cost for display
  const formattedTotalCost = totalCost ? Number(totalCost).toFixed(2) : "0.00";

  useEffect(() => {
    const userId = getUserIdFromLocalStorage();
    const items = cartItems.map(item => ({ id: item.id, type: item.type }));

    if (userId && totalCost > 0) { // Ensure userId and totalCost are valid before making API call
      fetch("https://api.andrewslearning.com/api/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          totalCost, 
          userId, 
          items, 
          discountCode, 
          subscriptionType, 
          discountApplied 
        }),
      })
      .then(res => res.json())
      .then(data => {
        console.log('Payment Intent created:', data);
        setClientSecret(data.clientSecret); // Set clientSecret once API response is received
      })
      .catch(error => console.error('Error creating payment intent:', error));
    } else {
      console.error('User ID not found in local storage or invalid totalCost.');
    }
  }, [cartItems, totalCost, discountCode, subscriptionType, discountApplied]);  // Dependencies for useEffect

  // Options for Stripe Elements
  const options = {
    clientSecret,
    appearance: { theme: 'stripe' },
  };

  return (
    <div id="Cartincheckout">
      <div className='checkoutcartContainer'>
        <div className='cartstuff'>
          <h2 className='Cartitems'>Cart Items</h2>
          {cartItems.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <ul>
              {cartItems.map(item => (
                <li className='Cartite' key={item.id}>
                  <div>
                    <h3 className="Cartitename">{item.name}</h3>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Render Stripe Elements only if clientSecret is available */}
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          {/* Pass the required values to CheckoutForm */}
          <CheckoutForm
          />
        </Elements>
      )}

      {/* Display the total cost */}
      <div className="cart-total2">
        <strong className="total">Total:</strong>
        <center><span>${formattedTotalCost}</span></center>
      </div>
    </div>
  );
}
