import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import ChatArea from '../ChatArea';
import ReconnectingWebSocket from 'reconnecting-websocket'; // Import Reconnecting WebSocket
import '../Chat.css';

const Chatapplocal = () => {
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const access_token = useSelector(state => state.userLogin.userInfo?.access);
  const userInfo = useSelector(state => state.userLogin.userInfo);
  const username = userInfo ? userInfo.username : 'Anonymous';

  useEffect(() => {
    if (!access_token) {
      console.error('Access token not found! Chat features will be disabled.');
      return;
    }

    const ws = new ReconnectingWebSocket('wss://api.andrewslearning.com/wschat/gptchatroom/');
    setSocket(ws);

    ws.onopen = () => {
      console.log('WebSocket connection established');
      ws.send(JSON.stringify({ message: 'Hello from client!', author: username }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: data.message, sent: false, author: data.author || 'Server' }
      ]);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket connection closed', event);
    };

    return () => {
      ws.close();
    };
  }, [access_token, username]);

  const sendMessage = (message) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ message, author: username }));
      setMessages((prevMessages) => [...prevMessages, { text: message, sent: true, author: username }]);
    } else {
      console.error('WebSocket is not connected or open.');
    }
  };

  if (!access_token) {
    return (
      <div className='chat-container'>
        <Sidebar />
        <div className="no-access">
          <h2>Access Denied</h2>
          <p>Please log in and try again.</p>
        </div>
      </div>
    );
  }

  return (
    <div className='chat-container'>
      <ChatArea sendMessage={sendMessage} messages={messages} />
    </div>
  );
};

export default Chatapplocal;
