import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { logout, getUserDetails, updateOnlineUsers } from './actions/userActions';
//import store from './store';
import HomePage from './components/HomePage';
import BlogList from './components/BlogList';
import BlogPost from './components/Blog';
import AllCalculators from './components/AllCalculators';
import MathComponent from './components/Algebralab';
import FunctionGraph from './components/Algebralab3';
import SolveEquation from './components/Algebralab4';
import SolveEquationSteps from './components/Algebralab5';
import Sketch from './components/Physicslab1';
import MomentumSketch from './components/Physicslab2';
import WaveSketch from './components/Physicslab3';
import RefractionSketch from './components/Physicslab4';
import PeriodicTable from './components/Chemlab';
import LoginForm from './components/Login';
import RegisterForm from './components/Registration';
import Register from './components/Register';
import SocialMedia from './components/SocialMedia';
import Header from './components/Header';
import Footer from './components/Footer';
import WaveInterference from './components/Physicslab5';
//import LockdownBrowser from './components/Lockdownbrowser';
//import Exam from './components/Exam';
import CoursesPage from './components/Courses';
import AssessmentPage from './components/AssesmentPage';
import CourseDetailPage from './components/CourseDetails';
import DrawingBoard from './components/Whiteboard';
import SetSimulator from './components/Algebralab2';
import Profile from './components/Profile';
import Cart from './components/Cart';
import UnitConversion from './components/UnitConversionlab';
import CATAssessmentPage from './components/CATassessmentpage';
import LoopVisualizer from './components/Loop';
import InteractiveLabs from './components/InteractiveLabs';
import AdaptiveAssessments from './components/adaptive_lists_assessments';
import QuestionCreateForm from './components/createQuestion';
import Checkout from './components/Checkoutpage';
import AssessmentReviewPage from './components/Assessmentresponse';
import ContactUsPage from './components/Contactus';
import AboutUs from './components/AboutUs';
import MoleculeViewer from './components/MoleculeViewer';
import Videolist from './components/Videolist';
import MatrixM from './MatrixMultiplication';
import VideoDetail from './components/Video';
import MolecularEditor from './components/chem';
import ThreeComponent from './Bohr_model_three';
import MatrixMultiplication from './MatrixMultiplication';
import LabThreeComponent from './lab_three_component';
import useSound from './Sound';
import YuhComponent from './test';
import YuhloaderComponent from './loadertest';
import AnatomyThreeScene from './components/AnatomyThree';
import ChatArea from './components/ChatArea';
import Chatapp from './components/Screens/chatapp';
import Chatapplocal from './components/Screens/chatapplocal';
import MachineLearningVisualization from './components/MLfix';
import LiveQuizPage from './components/Screens/LiveQuizPage';



function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const dispatch = useDispatch();
  
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const playClickSound = useSound('/ui-click-43196.mp3'); // Local file path
  

  useEffect(() => {
    if (!user || !user.id) {
      const userId = userInfo ? userInfo.id : null;
      if (userId) {
        dispatch(getUserDetails(userId));
      }
    }
  }, [dispatch, userInfo, user]);

  // State and references for WebSocket
  const [onlineUsers, setOnlineUsers] = useState([]); // State to track online users
  const ws = useRef(null); // Use a ref to store WebSocket instance

  useEffect(() => {
    if (userInfo) {
      ws.current = new WebSocket(`wss://api.andrewslearning.com/ws/onlinestatus/`);
  
      ws.current.onopen = () => {
        // Notify server of user connection
        ws.current.send(JSON.stringify({ type: 'user_connected', username: userInfo.username }));
      };
  
      ws.current.onmessage = (event) => {
        try {
          const messageData = JSON.parse(event.data);

          if (messageData.type === 'ping') {
            // Send 'pong' response to server
            ws.current.send(JSON.stringify({ type: 'pong' }));
            console.log('Pong sent in response to ping');
          } else if (messageData.type === 'user_status_change') {
            // Handle user status changes
            dispatch(updateOnlineUsers(messageData.username, messageData.online));
          }
        } catch (error) {
          console.error('Error parsing message data:', error);
        }
      };
  
      return () => {
        if (ws.current) ws.current.close();
      };
    }
  }, [userInfo, dispatch]);

  // Log out handler
  const logoutHandler = () => {
    dispatch(logout());
    if (ws.current) {
      ws.current.close(); // Close WebSocket on logout
    }
  };

  // Calculate the number of items in the cart
  const cartItemCount = cartItems.length;

//starting a bunch of bullshit if you mess up come here
  return (
    <Router>
      <SocialMedia />
      
      <Navbar className="navbar" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
            <Nav.Link href="/#Home" onClick={playClickSound} className="text-white">Home</Nav.Link>
            <Nav.Link onClick={playClickSound} href="/Blog" className="text-white">Blog</Nav.Link>

            <Nav.Link href="/videos" onClick={playClickSound} className="text-white">Videos</Nav.Link>
            <Nav.Link href="/InteractiveLabs#Interactive" onClick={playClickSound} className="text-white">Labs</Nav.Link>
            <Nav.Link href="/AdaptiveAssessments#Assessments" onClick={playClickSound} className="text-white">Assessments</Nav.Link>
            {userInfo ? (
              <NavDropdown className="text-white" title= {userInfo.username} id='username'>
                <LinkContainer to={`/Profile/${userInfo.id}`}>
                  <NavDropdown.Item onClick={playClickSound} className="text-black">Profile</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item className="text-black" onClick={logoutHandler}>Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link href="/Login#LoginForm" onClick={playClickSound} className="text-white">Login</Nav.Link>
            )}
            {!userInfo && (
              <Nav.Link href="/Register#RegistrationForm" onClick={playClickSound} className="text-white">Sign Up</Nav.Link>
            )}
            {userInfo && !user?.has_active_subscription && (
              <Nav.Link href="/Cart" onClick={playClickSound} className="text-silver">
                Premium
              </Nav.Link>
            )}
            <Nav.Link onClick={playClickSound} href="/About#About" className="text-white">About us</Nav.Link>
            <Nav.Link onClick={playClickSound} href="/Contact#Contact" className="text-white">Contact Us</Nav.Link>
            <Nav.Link href="/AdaGPT" onClick={playClickSound} className="text-white">AI tutor</Nav.Link>

          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="white-space"></div>

      <Header />

      <Routes>
      <Route path="/cart" element={<Cart />} />
        <Route path="/videos" element={<Videolist/>} />
        <Route path="/" element={<HomePage />} />
        <Route path="/Blog" element={<BlogList />} />
        <Route path="/Whiteboard" element={<DrawingBoard />} />
        <Route path="/posts/:postId" element={<BlogPost />} />
        <Route path="/InteractiveLabs" element={<InteractiveLabs />} />
        <Route path="/Calculators" element={<AllCalculators />} />
        <Route path="/Algebralab" element={<MathComponent />} />
        <Route path="/Algebralab2" element={<SetSimulator />} />
        <Route path="/Algebralab3" element={<FunctionGraph />} />
        <Route path="/Algebralab4" element={<SolveEquation />} />
        <Route path="/Algebralab5" element={<SolveEquationSteps />} />
        <Route path="/Unit Conversions" element={<UnitConversion />} />
        <Route path="/Physicslab1" element={<Sketch />} />
        <Route path="/Physicslab2" element={<MomentumSketch />} />
        <Route path="/Physicslab3" element={<WaveSketch />} />
        <Route path="/Physicslab4" element={<RefractionSketch />} />
        <Route path="/Physicslab5" element={<WaveInterference />} />
        <Route path="/Physicslab5" element={<WaveInterference />} />
        <Route path="/MoleculeViewer" element={<MoleculeViewer />} />
        <Route path="/Chemlab" element={<PeriodicTable />} />
        <Route path="/Login" element={<LoginForm />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Courses" element={<CoursesPage />} />
        <Route path="/assessments/:assessmentId" element={<AssessmentPage />} />
        <Route path="/catassessments/:assessmentId" element={<CATAssessmentPage />} />
        <Route path="/courses/:courseId" element={<CourseDetailPage />} />
        <Route path='/Profile/:id' element={<Profile />} />
        <Route path='/Checkout' element={<Checkout />} />
        <Route path='/Programming' element={<LoopVisualizer />} />
        <Route path='/AdaptiveAssessments' element={<AdaptiveAssessments />} />
        <Route path='/QuestionCreate' element={<QuestionCreateForm />} />
        <Route path='/Contact' element={<ContactUsPage />} />
        <Route path='/assessment_review/:assessmentResponseId' element={<AssessmentReviewPage />} />
        <Route path='/About' element={<AboutUs />} />
        <Route path='/Matrix' element={<MatrixMultiplication />} />
        <Route path='/lectures/:videoId' element={<VideoDetail />} />
        <Route path='/chemstuff' element={<MolecularEditor />} />
        <Route path='/Atom' element={<ThreeComponent />} />
        <Route path='/MM' element={<MatrixM />} />
        <Route path='/labcomponent' element={< LabThreeComponent />} />
        <Route path='/test' element={< YuhloaderComponent />} />
        <Route path='/Anatomy' element={< AnatomyThreeScene />} />
        <Route path='/Chat' element={<Chatapp />} />
        <Route path='/AdaGPT' element={<Chatapplocal />} />
        <Route path='/ML' element={<MachineLearningVisualization />} />
        <Route path='/livquiz' element={<LiveQuizPage/>} />

        

      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
